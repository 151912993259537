import { PageProps } from 'gatsby';
import React from 'react';
import BlogList from '../components/blog-list/blog-list';
import Layout from '../layouts/layout';
import SEO from '../components/seo';

const OnePage: React.FC<PageProps<null>> = () => (
  <Layout>
    <SEO title="Home" />

    <section>
      {/*<BlogList />*/}
      OnePage
    </section>

  </Layout>
);


export default OnePage;
